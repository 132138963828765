import React, { Component } from 'react'
import { Link } from 'gatsby'

import { graphql, StaticQuery } from 'gatsby'
import scrollTo from 'gatsby-plugin-smoothscroll';

class Nav extends Component {

  constructor(props) {
    super(props);
  }
  
  state = {
    navbarOpen: false,
  }

  toggleNavState = () => {
    this.setState({
      navbarOpen: !this.state.navbarOpen,
    })
  }

  closeNav = () => {
    this.setState({
      navbarOpen: false,
    })
  }

  render() {
    return (
      <nav
        className="container"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar is-transparent main-navigation">
          <div
            id="mainMenu"
            className={
              this.state.navbarOpen ? 'navbar-menu is-active' : 'navbar-menu'
            }
          >
            <div className="navbar-start">
              <StaticQuery
                query={graphql`
                  {
                    allMainMenuJson {
                      edges {
                        node {
                          id
                          type
                          url
                          title
                        }
                      }
                    }
                  }
                `}
                render={data =>
                  data.allMainMenuJson.edges.map(edge => {
                    return edge.node.type === 'internal' && typeof this.props.location !== "undefined" ? (
                      this.props.location.pathname === '/' ? 
                      <div
                        key={edge.node.id}
                        to={edge.node.url}
                        className="navbar-item is-clickable"
                        activeClassName="is-active"
                        onClick={() => {scrollTo(edge.node.url.replace(/\//g, "")); this.closeNav();}}
                      >
                        {edge.node.title}
                      </div> :
                      <Link
                        key={edge.node.id}
                        to={edge.node.url}
                        className="navbar-item"
                        activeClassName="is-active"
                        onClick={() => {scrollTo(edge.node.url.replace(/\//g, "")); this.closeNav();}}
                      >
                        {edge.node.title}
                      </Link>
                    ) : (
                      <a
                        key={edge.node.id}
                        className="navbar-item"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={edge.node.url}
                      >
                        {edge.node.title}
                      </a>
                    )
                  })
                }
              />
            </div>
          </div>
          <div className="navbar-brand">
            <span
              onClick={this.toggleNavState}
              role="button"
              className={
                this.state.navbarOpen
                  ? 'navbar-burger burger is-active'
                  : 'navbar-burger burger'
              }
              aria-label="menu"
              aria-expanded="false"
              data-target="mainMenu"
            >
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </span>
          </div>
        </div>
      </nav>
    )
  }
}

export default Nav