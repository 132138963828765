import React, { Component } from 'react'
import { Link } from 'gatsby'
import SiteConfig from '../../site-config.json'

class Footer extends Component {

  render() {
    return (
      <footer className="footer">
        <div className="container">
          <div className="columns">
            <div className="column">
              <strong className="title is-3 has-text-white pb-6">ZJ-Automobile</strong>
            </div>
            <div className="column">
              <strong className="title is-3 has-text-white">Öffnungszeiten</strong>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="columns">
            <div className="column">
              <p 
                className="mb-6"
                dangerouslySetInnerHTML={{__html: SiteConfig.address.replace(/\n/g, "<br/>")}}
              />
            </div>

            <div className="column">
              <table>
                {SiteConfig.open.map((open) => {
                  return(
                    <tr>
                      <td className="pb-2 pr-4">{open.zeiten.day}</td>
                      <td>{open.zeiten.time}</td>
                    </tr>
                  )
                })}
              </table>
            </div>
          </div>

          <div className="columns">
            <div className="column">
              <Link to="/datenschutz" className="white-link">Datenschutz</Link>
              &nbsp;|&nbsp; 
              <Link to="/impressum" className="white-link">Impressum</Link>
            </div>
            <div className="column">
              <div className="pt">Copyright &copy; {new Date().getFullYear()} ZJ-Automobile</div>
            </div>
          </div>

          <div className="columns">
            <small 
              className="column pt-6 mt-6"
              dangerouslySetInnerHTML={{__html: SiteConfig.footer_text.replace(/\n/g, "<br/>")}}
            />
          </div>

          <div className="columns">
            <small className="column pt-6 mt-6">
            Wir setzen eine cookiefreie Webanalyse (Matomo) ein. Informationen und eine Widerspruchsmöglichkeit finden Sie <a href="/datenschutz#optoutanchor" class="white-link">hier</a>
            </small>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer