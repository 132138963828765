import React from "react"
import { Helmet } from "react-helmet";

import siteConfig from "../../site-config.json";

export default function Meta({title}) {

  const description = siteConfig.description;

  return (
    <Helmet>
      <meta charSet="utf-8" />
      

      <meta name="description" content={description} />
      <meta name="author" content="ZJ-Automobile" />

      <title>{title}</title>

      <meta name="viewport" content="width=device-width" />
      <meta name="HandheldFriendly" content="true" />

      <meta name="og:title" content={title} />
      <meta name="og:description" content={description} />
      <meta name="og:type" content="website" />
      <meta name="twitter:creator" content="ZJ-Automobile" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:card" content="summary_large_image" />

      <link rel="canonical" href="https://zj-automobile.de" />

      <html lang="de" />
    </Helmet>
  )
} 