import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql, Link } from 'gatsby'

import Nav from './nav'
import Meta from './meta'
import Footer from './footer'
import '../styles/normalize.css'
import '../styles/main.sass'

const Layout = class extends React.Component {
   constructor(props) {
     super(props);
   }

   render() {
     const { children, className } = this.props;

     return (
       <StaticQuery
         query={graphql`
           query SiteTitleQuery {
             site {
               siteMetadata {
                 title
               }
             }
           }
         `}
         render={data => (
           <div className={className}>
             <Meta title={data.site.siteMetadata.title} />
             <Nav location={this.props.location} />
             {children}
             <Footer />
           </div>
         )}
       />
     );
   }
 }

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout